<template>
    <AppPanel>
        <template #content>
            <div>
                <AppPaginatedGrid ref="grid" :service="service" :actionItems="actionItems" @onToggleMenu="onToggleMenu"
                    subtitle="Integração Senior" tooltip="Sincronização com Senior" :filtrosExtras="filtrosExtras"
                    :hasFiltro="false">
                    <template #incluir-registro>
                        <Button v-if="$checkPermission('integracao_senior:criar')" label="Incluir" icon="pi pi-plus"
                            class="p-button p-button-text mr-2" @click="abrirDialogIntegracaoSenior()" />
                    </template>

                    <template #columns>
                        <Column field="cliente.id" header="Código" />
                        <Column field="cliente.name" header="Cliente" />
                        <Column field="unidade.nome" header="Unidade" />
                        <Column field="status" header="Status">
                            <template #body="{ data }">
                                <Tag v-tooltip="getTooltipError(data)" :style="tagStatusStyle(data.status)"
                                    :value="enumStatusIntegracaoSeniorFuncionario[data.status]" />
                            </template>
                        </Column>
                        <Column field="percentualIntegracao" header="Percentual" />
                        <Column field="codigoExecucao" header="Código Execução">
                            <template #body="{ data }">
                                {{ data.codigoExecucao ? data.codigoExecucao : '-' }}
                            </template>
                        </Column>
                        <Column field="createdAt" header="Criado em">
                            <template #body="{ data }">
                                {{ $filters.formatDateOnly(data.createdAt) }}
                            </template>
                        </Column>
                    </template>

                    <template #anotherFilters>
                        <div class="grid flex align-items-center gap-2 p-3">
                            <InputText v-model.trim="filtrosExtras.nomeCliente" placeholder="Pesquisar" class="col-3"
                                @keypress.enter="buscarIntegracoes" />
                            <DropdownCodigoExecucao v-model="filtrosExtras.codigoExecucao"
                                class="col-2 p-1"/>
                            <Button label="Pesquisar" @click="buscarIntegracoes()" />
                            <Button label="Atividade específica" @click="abrirDialogAtividadeEspecifica()" />
                            <Button label="Relatório de itens com erro" @click="baixarRelatorioItensComErro()" />
                            <div class="flex align-items-center gap-2">
                                <Checkbox id="filtroPossuiErro" :binary="true" v-model="filtrosExtras.possuiErro" />
                                <label for="filtroPossuiErro">Mostrar apenas com erro</label>
                            </div>
                        </div>
                    </template>
                </AppPaginatedGrid>
            </div>
            <DialogIntegracaoSenior ref="dialogIntegracaoSenior" @onLoad="buscarIntegracoes" />
            <DialogAtividadeEspecifica ref="dialogAtividadeEspecifica" @onLoad="buscarIntegracoes" />
        </template>
    </AppPanel>
</template>

<script>
import MicroserviceSenior from '../../services/MicroserviceSenior';
import { showError, showSuccess, showWarning } from '../../utils/Toast';
import DialogIntegracaoSenior from './components/DialogIntegracaoSenior';
import DialogAtividadeEspecifica from './components/DialogAtividadeEspecifica';
import StatusIntegracaoSeniorFuncionarioEnum from '../../enums/StatusIntegracaoSeniorFuncionarioEnum';
import { getClientMicroserviceSenior } from '../../services/http';
import { getCurrentCompany } from '@/services/store';
import { getTenant, getToken } from '../../common/storage';
import axios from 'axios';
import DropdownCodigoExecucao from './components/DropdownCodigoExecucao.vue';

export default {
    components: {
        DialogIntegracaoSenior,
        DialogAtividadeEspecifica,
        DropdownCodigoExecucao
    },
    data() {
        return {
            service: null,
            record: {},
            enumStatusIntegracaoSeniorFuncionario: StatusIntegracaoSeniorFuncionarioEnum,
            filtrosExtras: {
                possuiErro: false,
                nomeCliente: null,
                codigoExecucao: null,
                empresaId: getCurrentCompany()
            }
        };
    },
    computed: {
        exibirReprocessar() {
            const statusNaoPermitidos = [
                StatusIntegracaoSeniorFuncionarioEnum.CONCLUIDO,
                StatusIntegracaoSeniorFuncionarioEnum.ATUALIZANDO_DADOS,
                StatusIntegracaoSeniorFuncionarioEnum.BUSCANDO_DADOS_SENIOR,
                StatusIntegracaoSeniorFuncionarioEnum.BUSCANDO_DADOS_DUON,
                StatusIntegracaoSeniorFuncionarioEnum.BUSCANDO_DADOS_DUON,
                StatusIntegracaoSeniorFuncionarioEnum.CANCELADO
            ];
            return !statusNaoPermitidos.includes(StatusIntegracaoSeniorFuncionarioEnum[this.record?.status]);
        },
        actionItems() {
            return [
                {
                    label: 'Visualizar',
                    icon: 'pi pi-eye',
                    command: () => this.visualizar(this.record)
                },
                {
                    label: 'Baixar relatório',
                    icon: 'pi pi-download',
                    command: async () => await this.baixarRelatorio(this.record)
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    disabled: () => StatusIntegracaoSeniorFuncionarioEnum[this.record.status] === StatusIntegracaoSeniorFuncionarioEnum.ATUALIZANDO_DADOS,
                    command: async () => await this.cancelar(this.record)
                }
            ];
        }
    },
    created() {
        this.service = new MicroserviceSenior('/integracao/buscar-registros-integracoes');
    },
    watch: {
        'filtrosExtras.possuiErro'() {
            this.zerarPage();
        }
    },
    methods: {
        zerarPage() {
            this.$refs.grid.currentPage = 1;
            this.$refs.grid.load();
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        abrirDialogIntegracaoSenior() {
            this.$refs.dialogIntegracaoSenior.abrir();
        },
        abrirDialogAtividadeEspecifica() {
            this.$refs.dialogAtividadeEspecifica.abrir();
        },
        visualizar(record) {
            this.$router.push(`/integracao-senior/${record._id}`);
        },
        async cancelar(record) {
            try {
                if (
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.CANCELADO ||
                    StatusIntegracaoSeniorFuncionarioEnum[record.status] == StatusIntegracaoSeniorFuncionarioEnum.CONCLUIDO
                ) {
                    showWarning(this.$toast, 'Não é possível cancelar um registro com este status.');
                    return;
                }
                await getClientMicroserviceSenior().post(`integracao/cancelar-integracao/`, this.record);
                await this.buscarIntegracoes();
                showSuccess(this.$toast, 'Registro cancelado!');
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async baixarRelatorio(record) {
            try {
                const res = await axios({
                    url: process.env.VUE_APP_API_MICROSERVICE_SENIOR + `/integracao/baixar-relatorio`,
                    method: 'POST',
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        'x-api-key': getTenant()
                    },
                    data: record
                });
                const file = window.URL.createObjectURL(new Blob([res.data]));
                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', 'relatorio_integracao_senior.xlsx');
                document.body.appendChild(docUrl);
                docUrl.click();
                this.loadingExcel = false;

                showSuccess(this.$toast, 'Relatório gerado com sucesso');
            } catch (error) {
                showError(this.$toast, error.message || 'Erro ao gerar o relatório');
            }
        },
        async baixarRelatorioItensComErro() {
            try {
                const res = await axios({
                    url: process.env.VUE_APP_API_MICROSERVICE_SENIOR + `/integracao/baixar-relatorio-itens`,
                    method: 'POST',
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        'x-api-key': getTenant()
                    },
                    data: { empresaId: getCurrentCompany(), codigoExecucao: this.filtrosExtras.codigoExecucao },
                });

                const file = window.URL.createObjectURL(new Blob([res.data]));
                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', 'itens_com_erro_senior.xlsx');
                document.body.appendChild(docUrl);
                docUrl.click();
                this.loadingExcel = false;

                showSuccess(this.$toast, 'Relatório gerado com sucesso');
            } catch (error) {
                showError(this.$toast, error.message || 'Erro ao gerar o relatório');
            }
        },
        async buscarIntegracoes() {
            await this.$refs.grid.load();
        },
        getTooltipError(data) {
            if (
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INTEGRANDO_DADOS_SOC ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_BUSCANDO_DADOS_SENIOR ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_FILTRANDO_DADOS ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INSERINDO_ITEMS_INTEGRACAO ||
                StatusIntegracaoSeniorFuncionarioEnum[data.status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_PROCESSANDO_ITEMS_INTEGRACAO
            ) {
                return `Não foi possível processar o registro. Erro: ${data.erro}`;
            }

            return '';
        },
        tagStatusStyle(status) {
            if (
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INTEGRANDO_DADOS_SOC ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_BUSCANDO_DADOS_SENIOR ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_FILTRANDO_DADOS ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_INSERINDO_ITEMS_INTEGRACAO ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.ERRO_PROCESSANDO_ITEMS_INTEGRACAO ||
                StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.INTEGRACAO_INCOMPLETA
            ) {
                return {
                    'background-color': 'var(--pink-200)',
                    color: 'var(--pink-800)'
                };
            }

            if (StatusIntegracaoSeniorFuncionarioEnum[status] === StatusIntegracaoSeniorFuncionarioEnum.CONCLUIDO) {
                return {
                    'background-color': 'var(--green-300)',
                    color: 'var(--green-900)'
                };
            }

            return {
                'background-color': 'var(--blue-200)',
                color: 'var(--blue-900)'
            };
        }
    }
};
</script>
