<template>
    <Dialog v-model:visible="visivel" :style="{ width: '350px' }" header="Incluir" @hide="fechar">
        <div class="w-full">
            <label for="cliente">Cliente</label>
            <DropdownCliente
                id="cliente"
                v-model="cliente"
                class="w-full"
                item-value="id"
                :class="{ 'p-invalid': v$.cliente.$error }"
                :filtrosExtras="{ ativo: true, integraFuncionarioSenior: true }"
            />
            <small v-if="v$.cliente.$error" class="p-error">
                {{ v$.cliente.$errors[0].$message }}
            </small>
        </div>
        <template #footer>
            <Button class="p-button-text" icon="pi pi-times" label="Cancelar" :disabled="loading" @click="fechar" />
            <Button label="Confirmar" icon="pi pi-check" :loading="loading" @click="validarCampos" />
        </template>
    </Dialog>
</template>

<script>
import { showError, showSuccess } from '../../../utils/Toast';
import { useVuelidate } from '@vuelidate/core';
import DropdownCliente from '../../clientes/components/DropdownCliente';
import { helpers, required } from '@vuelidate/validators';
import MicroserviceSenior from '../../../services/MicroserviceSenior';

export default {
    emits: ['onLoad'],
    components: {
        DropdownCliente
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            visivel: false,
            loading: false,
            service: null,
            cliente: null
        };
    },
    validations() {
        return {
            cliente: {
                required: helpers.withMessage('Cliente obrigatório', required)
            }
        };
    },
    mounted() {
        this.service = new MicroserviceSenior('integracao/criar-registro-integracao');
    },
    methods: {
        abrir() {
            this.visivel = true;
        },
        fechar() {
            this.visivel = false;
            this.formulario = {};
        },
        async confirmar() {
            this.loading = true;
            try {
                await this.service.save({
                    clienteId: this.cliente?.id
                });

                showSuccess(this.$toast, 'Iniciado processamento. Atualize para acompanhar!');
                this.$emit('onLoad');
                this.fechar();
            } catch (error) {
                showError(this.$toast, error);
            }
            this.loading = false;
        },
        async validarCampos() {
            const valido = await this.v$.$validate();
            if (valido) this.confirmar();
        }
    }
};
</script>
