import { getClientMicroserviceSenior } from './http';

export default class MicroserviceSenior {
    constructor(path, auxHeaders) {
        this.path = path;
        this.auxHeaders = auxHeaders;
    }

    findAll({ page = 1, limit = 10, filter = '', sort = '', ...otherParams }) {
        return getClientMicroserviceSenior(this.auxHeaders).get(this.path, {
            params: {
                page,
                limit,
                filter,
                sort,
                ...otherParams
            }
        });
    }

    get() {
        return getClientMicroserviceSenior(this.auxHeaders).get(this.path);
    }

    save(record) {
        if (record._id) {
            return getClientMicroserviceSenior(this.auxHeaders).patch(`${this.path}/${record._id}`, record);
        }
        return getClientMicroserviceSenior(this.auxHeaders).post(this.path, record);
    }

    remove(id) {
        return getClientMicroserviceSenior(this.auxHeaders).delete(`${this.path}/${id}`);
    }
}
