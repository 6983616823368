const StatusIntegracaoSeniorFuncionarioEnum = Object.freeze({
    CRIADO: 'Criado',
    BUSCANDO_DADOS_SENIOR: 'Buscando Dados Senior',
    ERRO_BUSCANDO_DADOS_SENIOR: 'Erro ao Buscar Dados Senior',
    BUSCANDO_DADOS_DUON: 'Buscando Dados Duon',
    ERRO_BUSCANDO_DADOS_DUON: 'Erro ao Buscar Dados Duon',
    BUSCA_CONCLUIDA: 'Busca Concluída',
    ERRO_MAPEANDO_DADOS: 'Erro ao mapear dados',
    DADOS_MAPEADOS: 'Dados Mapeados',
    DADOS_COMPARADOS: 'Dados Comparados',
    ERRO_COMPARANDO_DADOS: 'Erro ao Comparar Dados',
    ITENS_CRIADOS: 'Itens Criados',
    ERRO_AO_CRIAR_ITENS: 'Erro ao criar itens',
    ATUALIZANDO_DADOS: 'Atualizando Dados',
    INTEGRACAO_INCOMPLETA: 'Integração incompleta',
    CONCLUIDO: 'Concluído',
    CANCELADO: 'Cancelado',
})

export default StatusIntegracaoSeniorFuncionarioEnum;
