<script setup>
import { ref, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { showError } from '@/utils/Toast';
import MicroserviceSenior from '@/services/MicroserviceSenior';

const visivel = ref(false);
const loading = ref(false);
const formulario = ref({});
const atividadesEspecificas = ref([]);

const service = new MicroserviceSenior('/atividade-especifica');
const toast = useToast();

const rules = {
    idOrigem: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    idDestino: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    nome: {
        required: helpers.withMessage('Campo obrigatório', required)
    }
};
const v$ = useVuelidate(rules, formulario);

async function buscar() {
    loading.value = true;
    try {
        const { data } = await service.findAll({});
        atividadesEspecificas.value = data;
    } catch (error) {
        showError(toast, error);
    }
    loading.value = false;
}

async function excluir(id) {
    loading.value = true;
    try {
        await service.remove(id);
        buscar();
    } catch (error) {
        showError(toast, error);
    }
    loading.value = false;
}

async function salvar() {
    loading.value = true;
    try {
        await service.save(formulario.value);
        limparCampos();
        buscar();
    } catch (error) {
        showError(toast, error);
    }
    loading.value = false;
}

async function validarCampos() {
    const formularioCorreto = await v$.value.$validate();
    if (formularioCorreto) {
        v$.value.$reset();
        salvar();
    }
}

function abrir() {
    visivel.value = true;
    buscar();
}

function editar(item) {
    formulario.value = { ...item };
}

function fechar() {
    visivel.value = false;
    formulario.value = {};
}

function limparCampos() {
    formulario.value = {};
}

defineExpose({ abrir });
</script>
<template>
    <Dialog v-model:visible="visivel" :style="{ width: '800px' }" header="Atividades específicas" @hide="fechar">
        <div class="grid p-0 m-0">
            <div class="col-6 pl-0">
                <label for="cliente">Nome</label>
                <InputText v-model.trim="formulario.nome" :class="{ 'p-invalid': v$.nome.$error }" class="w-full" />
                <small v-if="v$.nome.$error" class="p-error">
                    {{ v$.nome.$errors[0].$message }}
                </small>
            </div>
            <div class="col-3 px-1">
                <label for="cliente">Id origem</label>
                <InputText v-model.trim="formulario.idOrigem" :class="{ 'p-invalid': v$.idOrigem.$error }" class="w-full" />
                <small v-if="v$.idOrigem.$error" class="p-error">
                    {{ v$.idOrigem.$errors[0].$message }}
                </small>
            </div>
            <div class="col-3 pr-0">
                <label for="cliente">Id destino</label>
                <InputText v-model.trim="formulario.idDestino" :class="{ 'p-invalid': v$.idDestino.$error }" class="w-full" />
                <small v-if="v$.idDestino.$error" class="p-error">
                    {{ v$.idDestino.$errors[0].$message }}
                </small>
            </div>
        </div>
        <div class="flex gap-2 justify-content-end pb-2">
            <Button class="p-button-text" label="Cancelar" :disabled="loading" @click="limparCampos()" />
            <Button label="Salvar" :loading="loading" @click="validarCampos()" />
        </div>
        <DataTable :value="atividadesEspecificas" dataKey="id" scrollable scrollHeight="250px">
            <Column field="editar" header-class="">
                <template #body="{ data }">
                    <Button :disabled="loading" icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="editar(data)" />
                </template>
            </Column>
            <Column field="nome" header="Nome" header-class="w-7" />
            <Column field="idOrigem" header="Id origem" header-class="w-2" />
            <Column field="idDestino" header="Id destino" header-class="w-2" />
            <Column field="excluir" header-class="">
                <template #body="{ data }">
                    <Button
                        :disabled="loading"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text p-button-danger"
                        @click="excluir(data._id)"
                    />
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>
