<template>
  <Dropdown v-model="value" :options="items" optionValue="value" optionLabel="label"
    placeholder="Selecione o código de execução" />
</template>

<script>
import MicroserviceSenior from '../../../services/MicroserviceSenior';

export default {
  props: {
    modelValue: {
      type: Object,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      items: [],
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  async created() {
    const response = await new MicroserviceSenior('/codigo-execucao').get();
    this.items = response.data.items.map((item) => {
      const dataFormatada = new Date(item.updatedAt).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
      return {
        value: item.codigo,
        label: `${item.codigo} - ${dataFormatada}`,
      };
    });
  },
};
</script>
